<template>
  <div>
    <div class="elv-financials-header-main-right">
      <el-button
        v-if="props.tableType === 'reviewed' && reportStore.entityDetail?.hasIntegration"
        :disabled="integrationSyncing"
        :loading="integrationSyncLoading"
        class="elv-financials-header-main-right__sync"
        @click="onSyncIntegration"
      >
        <template v-if="!integrationSyncing">
          <!-- <img src="@/assets/img/reports/erp-Quikbooks-icon.png" alt="Quikbooks" /> -->
          <img src="@/assets/img/reports/erp-Xero-icon.png" alt="Xero" />
        </template>
        <img v-else src="@/assets/img/reports/sources-sync-loading.png" alt="loading" class="elv-syncing" />
        {{
          reportStore.entityDetail.status === 'WAITING'
            ? t('report.generating')
            : t('button.syncIntegration', { platform: 'Xero' })
        }}
      </el-button>

      <el-button :disabled="isDisabled" class="elv-financials-header-main-right__edit" @click="onBatchEdit">
        <SvgIcon name="reports-batch-edit" width="16" height="16" />
        {{ t('button.edit') }}({{ utils.formatNumber(reportStore.batchEditSelected.length) }})
      </el-button>

      <elv-button v-if="props.tableType === 'forReview'" height="32" :loading="reviewLoading" @click="onReviewedAll">{{
        reportStore.batchEditSelected.length ? t('button.markSelectedAsReviewed') : t('button.markAllAsReviewed')
      }}</elv-button>

      <el-button
        v-if="props.tableType === 'reviewed'"
        :loading="exportLoading"
        class="elv-financials-header-main-right__export"
        @click="onExport"
      >
        <SvgIcon name="reports-export" width="16" height="16" />
        {{ t('button.export') }}
      </el-button>
    </div>
    <MessageBox
      ref="exportMessageBoxRef"
      :show-cancel-button="false"
      :confirm-button-text="t('button.confirm')"
      :title="t('title.exceedsExportLimit')"
      @onConfirmEvent="onConfirmEvent"
    >
      <template #content>
        <span class="elv-confirm-content-info__title">{{ t('message.exportLimitMessage') }}</span></template
      >
    </MessageBox>

    <MessageBox
      ref="reviewMessageBoxRef"
      :show-cancel-button="false"
      :confirm-button-text="t('button.iKnow')"
      :title="
        reviewData?.reviewed + reviewData?.noSet >= 20000
          ? t('message.maximumTransactionsReviewedInfo', { total: '20,000' })
          : t('message.someTransactionsReviewedInfo')
      "
      class="elv-reviews-message-box"
      @onConfirmEvent="onCloseReviewMessageBox"
    >
      <template #content>
        <p class="elv-reviews-message-info">
          <SvgIcon name="review-success" width="16" height="16" />{{
            t('message.reviewedSuccessfullyTotal', { total: utils.formatNumber(reviewData?.reviewed) })
          }}
        </p>
        <p v-if="reviewData?.noSet > 0" class="elv-reviews-message-info">
          <SvgIcon name="review-tag" width="16" height="16" />{{
            t('message.restReviewTotal', { total: utils.formatNumber(reviewData?.noSet) })
          }}
        </p>
      </template>
    </MessageBox>

    <MessageBox
      v-if="props.tableType === 'reviewed' && reportStore.entityDetail?.hasIntegration"
      ref="syncMessageBoxRef"
      :show-cancel-button="false"
      :loading="syncLoading"
      :confirm-button-text="t('button.iKnow')"
      :title="t('button.syncIntegration', { platform: 'Xero' })"
      @onConfirmEvent="onConfirmSync"
    >
      <template #content>
        <span class="elv-confirm-content-info__title">{{ t('message.syncIntegrationInfo') }}</span></template
      >
    </MessageBox>

    <AccountMappingsDialog ref="accountMappingsRef" mode="create" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import MessageBox from '@/components/MessageBox.vue'
import { map, some, isEmpty, difference } from 'lodash-es'
import { useReportStore } from '@/stores/modules/reports/index'
import AccountMappingsDialog from '@/components/Reports/AccountMappingsDialog.vue'

const props = defineProps({
  tableType: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const emit = defineEmits(['onCheckBatchTransactions'])

const exportLoading = ref(false)
const reviewLoading = ref(false)
const syncLoading = ref(false)
const integrationSyncing = ref(false)
const integrationSyncLoading = ref(false)
const syncMessage = ref(true)
const reviewData: any = ref({})
const syncMessageBoxRef = ref()
const accountMappingsRef = ref()
const exportMessageBoxRef = ref()
const reviewMessageBoxRef = ref()

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const isDisabled = computed(() => {
  return !isEmpty(reportStore.batchReviewParams)
})

const onConfirmEvent = () => {
  exportMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCloseReviewMessageBox = () => {
  reviewMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmSync = async () => {
  syncMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onBatchEdit = () => {
  if (!currentEntityPermission.value?.transaction?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.batchEditSelected.length) {
    emit('onCheckBatchTransactions')
  }
}

const onReviewedAll = async () => {
  try {
    if (!currentEntityPermission.value?.transaction?.review) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    reviewLoading.value = true
    if (reportStore.batchEditSelected.length) {
      const params: any = {
        activityIds: []
      }
      const activityIdList = map(reportStore.batchEditSelected, 'activityId')
      params.activityIds = difference(activityIdList, reportStore.unSelectedList)
      const { data } = await ReportsApi.quickReview(entityId.value, params)
      reviewData.value = data
      reportStore.batchEditSelected = []
    } else {
      const { data } = await ReportsApi.allReview(entityId.value, reportStore.transactionFilter)
      reviewData.value = data
    }
    reviewLoading.value = false
    if (reviewData.value?.noSet > 0) {
      reviewMessageBoxRef.value.onCheckMessageBoxDialog()
    } else {
      ElMessage.success(t('message.reviewSuccess'))
    }
    if (reviewData.value?.reviewed > 0) {
      emitter.emit('resetList')
    }
    const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
    const transactionFilter = {
      ...reportStore.transactionFilter,
      categoryNos: data
    }
    reportStore.processTableListDataInit(entityId.value, 'reviewed', {
      limit: 10,
      page: 1,
      ...transactionFilter
    })
  } catch (error: any) {
    reviewLoading.value = false
    ElMessage.error(error.message)
  }
}

const onExport = async () => {
  try {
    if (!currentEntityPermission.value?.transaction?.export) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    exportLoading.value = true
    const response: any = await ReportsApi.getActivityList(entityId.value, {
      page: 1,
      limit: 1000,
      reviewStatus: 'REVIEWED',
      ...reportStore.transactionFilter
    })
    if (response.data?.totalCount > 1000) {
      nextTick(() => {
        exportMessageBoxRef.value?.onCheckMessageBoxDialog()
      })
      exportLoading.value = false
    } else {
      const fileName = `${reportStore.entityDetail?.name} Transactions ${dayjs().format('YYYY-MM-DD')}.csv`
      await utils.exportExcel(response.data.list, fileName)
      ElMessage.success(`Export Success`)
      exportLoading.value = false
    }
  } catch (error: any) {
    exportLoading.value = false
    ElMessage.error(error.message)
    console.log(error)
  }
}

const onSyncIntegration = async () => {
  try {
    if (!currentEntityPermission.value?.transaction?.erp) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    integrationSyncLoading.value = true
    await reportStore.fetchEntityDetail(entityId.value)
    if (reportStore.entityDetail.needRegenerateReport && reportStore.entityDetail.status === 'FINISHED') {
      await ReportsApi.getRegenerateReport(entityId.value)
      syncMessageBoxRef.value?.onCheckMessageBoxDialog()
      integrationSyncing.value = true
      syncMessage.value = false
      integrationSyncLoading.value = false
      return
    }
    if (reportStore.entityDetail.needRegenerateReport && reportStore.entityDetail.status === 'WAITING') {
      syncMessageBoxRef.value?.onCheckMessageBoxDialog()
      integrationSyncLoading.value = false
      return
    }
    await reportStore.fetchAccountMappingList(entityId.value)
    if (reportStore.accountMapping.system.length) {
      const hasNull = some(reportStore.accountMapping.system, { integrationAccountMappingId: null })
      if (hasNull) {
        accountMappingsRef.value?.onCheckAccountMappingsDialog()
      } else {
        await ReportsApi.syncJournalIntegration(entityId.value)
        integrationSyncing.value = true
        reportStore.fetchEntityDetail(entityId.value)
      }
    }
    integrationSyncLoading.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}

watch(
  () => reportStore.entityDetail,
  () => {
    if (reportStore.entityDetail?.hasIntegration) {
      if (
        integrationSyncing.value &&
        reportStore.entityDetail?.status !== 'ERP_SYNCING' &&
        reportStore.entityDetail?.status !== 'WAITING' &&
        syncMessage.value
      ) {
        ElMessage.success(t('message.syncIntegrationSuccess', { platform: 'Xero' }))
      }
      integrationSyncing.value =
        reportStore.entityDetail?.status === 'ERP_SYNCING' ||
        (reportStore.entityDetail?.status === 'WAITING' && reportStore.entityDetail?.needRegenerateReport)

      syncMessage.value = !(
        reportStore.entityDetail?.status === 'WAITING' && reportStore.entityDetail?.needRegenerateReport
      )
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-financials-header-main-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.elv-financials-header-main-right__edit,
.elv-financials-header-main-right__sync {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border: 1px solid #dde1e6;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;

  svg {
    fill: #1e2024;
    margin-right: 8px;
  }

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):active {
    border: 1px solid #7596eb;
    background-color: #fff;
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-financials-header-main-right__sync {
  img {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 8px;
  }

  .elv-syncing {
    @keyframes loading-rotate {
      to {
        transform: rotate(360deg);
      }
    }
    animation: loading-rotate 2s linear infinite;
  }
}

.elv-financials-header-main-right__export {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border: 1px solid #dde1e6;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;
  margin-left: 8px;

  svg {
    fill: #1e2024;
    margin-right: 8px;
  }

  &:hover {
    border: 1px solid #7596eb;
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }
}

:deep(.elv-reviews-message-box.el-dialog) {
  .el-dialog__header {
    justify-content: flex-start;
    padding-left: 31px;

    .elv-confirm-info-header__title {
      font-weight: 700;
      text-align: left;
    }
  }

  .el-dialog__body {
    padding-left: 31px;
    flex-direction: column;
    align-items: flex-start;

    .elv-reviews-message-info {
      display: flex;
      align-items: center;
      color: #0e0f11;
      font-size: 14px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 7px;

      svg {
        margin-right: 9px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
