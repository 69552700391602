<template>
  <div class="elv-transactions-screening-container">
    <div class="elv-transactions-screening-content">
      <!-- Type -->
      <el-select
        v-model="typeValue"
        filterable
        show-input="false"
        popper-class="elv-transactions-screening-type-popper"
        class="elv-transactions-screening-type-select"
        :disabled="isDisabled"
        @change="onChangeType"
      >
        <template #prefix>
          <div class="elv-transactions-screening-button" :class="{ 'is-disabled': isDisabled }">
            <SvgIcon class="elv-transactions-screening-button__icon" name="transactions-type" width="16" height="16" />
            <div class="elv-transactions-screening-button__title">{{ t('button.type') }}</div>
            <span class="elv-transactions-screening-button__total">{{ typeValue === 'ALL' ? 2 : 1 }}</span>
          </div>
        </template>
        <el-option label="All Type" :value="'ALL'" />
        <el-option label="Transfer In" :value="'IN'" />
        <el-option label="Transfer Out" :value="'OUT'" />
      </el-select>

      <!-- Sources -->
      <el-popover
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-screening-sources-popper"
        :show-arrow="true"
        :offset="0.2"
        :disabled="isDisabled"
      >
        <template #reference>
          <div class="elv-transactions-screening-button" :class="{ 'is-disabled': isDisabled }">
            <SvgIcon
              class="elv-transactions-screening-button__icon"
              name="transactions-sources"
              width="16"
              height="16"
            />
            <div class="elv-transactions-screening-button__title">{{ t('button.sources') }}</div>
            <span class="elv-transactions-screening-button__total">{{
              checkedSources[0]?.length +
              checkedSources[1]?.length +
              checkedSources[2]?.length +
              checkedSources[3]?.length
            }}</span>
          </div>
        </template>
        <el-checkbox
          v-model="sourceState.checkAll"
          :indeterminate="sourceState.isIndeterminate"
          class="elv-transactions-screening-sources-checkAll"
          @change="onCheckSourceAllChange"
          >{{ t('report.allSources')
          }}<span
            v-if="
              checkedSources[0]?.length +
              checkedSources[1]?.length +
              checkedSources[2]?.length +
              checkedSources[3]?.length
            "
            >({{
              checkedSources[0]?.length +
              checkedSources[1]?.length +
              checkedSources[2]?.length +
              checkedSources[3]?.length
            }})</span
          ></el-checkbox
        >
        <el-scrollbar max-height="384px">
          <template v-if="sourcesData?.wallet?.length">
            <el-checkbox
              v-model="sourceState.walletCheckAll"
              :indeterminate="sourceState.walletIsIndeterminate"
              class="elv-transactions-screening-sources-group-checkAll"
              @change="onCheckSourceClassifyAllChange('wallet', 0, $event)"
              >{{ t('common.wallets')
              }}<span v-if="checkedSources[0].length">({{ checkedSources[0].length }})</span></el-checkbox
            >
            <el-checkbox-group v-model="checkedSources[0]" @change="onCheckedSourcesChange('wallet', $event)">
              <el-checkbox v-for="item in sourcesData.wallet" :key="item.sourceName" :label="item.sourceId">
                <img :src="currentLogo(item)" :alt="currentLogoAlt(item)" />
                <span>{{ item.sourceName }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <template v-if="sourcesData?.exchange?.length">
            <el-checkbox
              v-model="sourceState.exchangeCheckAll"
              :indeterminate="sourceState.exchangeIsIndeterminate"
              class="elv-transactions-screening-sources-group-checkAll"
              @change="onCheckSourceClassifyAllChange('exchange', 1, $event)"
              >{{ t('common.exchange')
              }}<span v-if="checkedSources[1]?.length">({{ checkedSources[1]?.length }})</span></el-checkbox
            >
            <el-checkbox-group v-model="checkedSources[1]" @change="onCheckedSourcesChange('exchange', $event)">
              <el-checkbox v-for="item in sourcesData.exchange" :key="item.name" :label="item.sourceId">
                <img :src="currentLogo(item)" :alt="currentLogoAlt(item)" />
                <span>{{ item.sourceName }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <template v-if="sourcesData?.csv?.length">
            <el-checkbox
              v-model="sourceState.csvCheckAll"
              :indeterminate="sourceState.csvIsIndeterminate"
              class="elv-transactions-screening-sources-group-checkAll"
              @change="onCheckSourceClassifyAllChange('csv', 2, $event)"
              >CSV<span v-if="checkedSources[2]?.length">({{ checkedSources[2]?.length }})</span></el-checkbox
            >
            <el-checkbox-group v-model="checkedSources[2]" @change="onCheckedSourcesChange('csv', $event)">
              <el-checkbox v-for="item in sourcesData.csv" :key="item.sourceName" :label="item.sourceId">
                <SvgIcon name="sources-csv" width="18" height="18" />
                <span>{{ item.sourceName }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <template v-if="sourcesData?.api?.length">
            <el-checkbox
              v-model="sourceState.apiCheckAll"
              :indeterminate="sourceState.apiIsIndeterminate"
              class="elv-transactions-screening-sources-group-checkAll"
              @change="onCheckSourceClassifyAllChange('api', 3, $event)"
              >API<span v-if="checkedSources[3]?.length">({{ checkedSources[3]?.length }})</span></el-checkbox
            >
            <el-checkbox-group v-model="checkedSources[3]" @change="onCheckedSourcesChange('api', $event)">
              <el-checkbox v-for="item in sourcesData.api" :key="item.sourceName" :label="item.sourceId">
                <img
                  v-if="item.sourceName === 'everPay'"
                  src="https://static.elven.com/project/everpay.png"
                  alt="everPay"
                />
                <SvgIcon v-else name="sources-csv" width="18" height="18" />
                <span>{{ item.sourceName }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-scrollbar>
      </el-popover>

      <!-- Events -->
      <el-popover
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-screening-events-popper"
        :show-arrow="true"
        :offset="0.2"
        :disabled="isDisabled"
      >
        <template #reference>
          <div class="elv-transactions-screening-button" :class="{ 'is-disabled': isDisabled }">
            <SvgIcon
              class="elv-transactions-screening-button__icon"
              name="transactions-events"
              width="16"
              height="16"
            />
            <div class="elv-transactions-screening-button__title">{{ t('button.categories') }}</div>
            <span class="elv-transactions-screening-button__total">{{
              eventOverlayDropdownRef?.checkedEvents.length
            }}</span>
          </div>
        </template>
        <TransactionOverlayDropdown ref="eventOverlayDropdownRef" :drop-down-data="eventData" />
      </el-popover>

      <!-- Currencies -->
      <el-popover
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-screening-currencies-popper"
        :show-arrow="true"
        :offset="0.2"
        :disabled="isDisabled"
      >
        <template #reference>
          <div class="elv-transactions-screening-button" :class="{ 'is-disabled': isDisabled }">
            <SvgIcon
              class="elv-transactions-screening-button__icon"
              name="transactions-currency"
              width="16"
              height="16"
            />
            <div class="elv-transactions-screening-button__title">{{ t('button.currencies') }}</div>
            <span class="elv-transactions-screening-button__total">{{ checkedCurrencies.length }}</span>
          </div>
        </template>
        <template v-if="currenciesData.length">
          <el-checkbox
            v-model="checkCurrenciesAll"
            :indeterminate="isIndeterminate"
            class="elv-transactions-screening-currencies-checkAll"
            @change="handleCheckAllChange"
            ><p>
              All Currencies<span v-if="checkedCurrencies.length">({{ checkedCurrencies.length }})</span>
            </p>
          </el-checkbox>
          <el-scrollbar max-height="384px">
            <el-checkbox-group v-model="checkedCurrencies" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in currenciesData" :key="item.symbol" :label="item.symbol">
                <img :src="item.logo" :alt="item.name" />
                <span>{{ item.symbol }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-scrollbar>
        </template>
      </el-popover>

      <!-- Contacts -->
      <el-popover
        v-if="entityId !== 78"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-screening-contacts-popper"
        :show-arrow="true"
        :offset="0.2"
        :disabled="isDisabled"
      >
        <template #reference>
          <div class="elv-transactions-screening-button" :class="{ 'is-disabled': isDisabled }">
            <SvgIcon
              class="elv-transactions-screening-button__icon"
              name="transactions-contacts"
              width="16"
              height="16"
            />
            <div class="elv-transactions-screening-button__title">{{ t('button.contacts') }}</div>
            <span
              v-if="
                !(
                  contactsOverlayDropdownRef?.checkedEvents[0] === 0 &&
                  contactsOverlayDropdownRef?.checkedEvents.length === 1
                )
              "
              class="elv-transactions-screening-button__total"
              >{{ contactsOverlayDropdownRef?.checkedEvents.length ? contactTotal : 0 }}</span
            >
          </div>
        </template>
        <TransactionOverlayDropdown ref="contactsOverlayDropdownRef" :drop-down-data="contactsData" type="contacts" />
      </el-popover>

      <!-- Datetime -->
      <div
        ref="dateTimeRef"
        v-click-outside="onCloseDateTime"
        class="elv-transactions-screening-button"
        :class="{ 'is-disabled': isDisabled }"
        @click="onOpenDateTime"
      >
        <SvgIcon class="elv-transactions-screening-button__icon" name="transactions-dateTime" width="16" height="16" />
        <div class="elv-transactions-screening-button__title">{{ t('button.dateTime') }}</div>
        <span class="elv-transactions-screening-button__total">{{ t('button.allTime') }}</span>
      </div>
      <div v-show="false">
        <el-date-picker
          ref="popoverRef"
          v-model="dateTimeValue"
          popper-class="elv-transactions-screening-dateTime-popper"
          :virtual-ref="dateTimeRef"
          virtual-triggering
          type="datetimerange"
          value-format="YYYY-MM-DD"
          :clearable="false"
          :shortcuts="shortcuts"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="onChangeTime"
        />
      </div>

      <el-button class="elv-transactions-screening-reset" :class="{ 'is-disabled': isDisabled }" @click="resetFilter">{{
        t('button.reset')
      }}</el-button>
    </div>

    <Operating :table-type="props.tableType" @onCheckBatchTransactions="onCheckBatchTransactions" />
  </div>
</template>

<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import { useI18n } from 'vue-i18n'
import Operating from './Operating.vue'
import { ClickOutside as vClickOutside } from 'element-plus'
import TransactionOverlayDropdown from './OverlayDropdown.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import { omit, isEmpty, isObject, isString, capitalize } from 'lodash-es'

const props = defineProps({
  tableType: {
    type: String,
    required: true
  }
})

const route = useRoute()
// const router = useRouter()
const { t } = useI18n()
const reportStore = useReportStore()

const emit = defineEmits(['onCheckBatchTransactions'])

const dateTimeRef = ref()
const eventOverlayDropdownRef = ref()
const contactsOverlayDropdownRef = ref()
const typeValue = ref('ALL')
const dateTimeValue: any = ref('')
const popoverRef = ref()

const shortcuts = [
  {
    text: 'All Time',
    value: () => {
      // eslint-disable-next-line no-unused-vars
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return 'All Time'
    }
  },
  {
    text: 'Last 7d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: 'Last 30d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  }
]

const checkAll = ref(true)
const checkCurrenciesAll = ref(true)
const sourceState: any = reactive({
  checkAll: false,
  walletCheckAll: false,
  exchangeCheckAll: true,
  csvCheckAll: true,
  apiCheckAll: true,
  isIndeterminate: true,
  walletIsIndeterminate: true,
  exchangeIsIndeterminate: false,
  csvIsIndeterminate: false,
  apiIsIndeterminate: false
})

const isIndeterminate = ref(false)
const checkedCurrencies: any = ref([])
const checkedSources = ref([[], [], [], []])

const sourcesData: any = computed(() => {
  return {
    wallet: reportStore.sourceList.wallet,
    exchange: reportStore.sourceList.exchange,
    csv: reportStore.sourceList.csv,
    api: reportStore.sourceList.api
  }
})

const eventData = computed(() => {
  return reportStore.eventDataList('filter')
})

const contactsData = computed(() => {
  if (reportStore.contactList.length || reportStore.contactUnnamedList.length) {
    const data = reportStore.contactList.map((itemData: any) => {
      const list = itemData.list.map((listItem: any) => {
        return {
          id: listItem.contactId,
          ...listItem
        }
      })
      return {
        groupName: capitalize(itemData.type),
        children: [{ list }]
      }
    })
    if (reportStore.contactUnnamedList.length) {
      const unnamedList = []
      unnamedList.push(...reportStore.contactUnnamedList.slice(0, 10))
      if (reportStore.contactUnnamedList.length > 10) {
        unnamedList.push({ name: 'Other', contactId: 0, id: 'other' })
      }
      data.push({
        groupName: 'Unnamed',
        children: [{ list: unnamedList }]
      })
    }
    data.push({
      groupName: 'No contacts',
      children: [
        {
          list: [{ name: 'No contacts', contactId: -1, id: -1 }]
        }
      ]
    })
    return data
  }
  const List = []
  if (reportStore.contactUnnamedList.length) {
    const unnamedList = []
    unnamedList.push(reportStore.contactUnnamedList.slice(0, 10))
    if (reportStore.contactUnnamedList.length > 10) {
      unnamedList.push({ name: 'Other', contactId: 0, id: 'other' })
    }
    List.push({
      groupName: 'Unnamed',
      children: [{ list: unnamedList }]
    })
  }
  List.push({
    groupName: 'No contacts',
    children: [
      {
        list: [{ name: 'No contacts', contactId: -1, id: -1 }]
      }
    ]
  })
  return List
})

const isDisabled = computed(() => {
  return !isEmpty(reportStore.batchReviewParams)
})

const currentLogo = computed(() => (item: any) => {
  return item.platform?.logo ?? ''
})
const currentLogoAlt = computed(() => (item: any) => {
  return item.platform?.name ?? ''
})

const currenciesData: any = computed(() => {
  return reportStore.currencyList
})

const contactTotal = computed(() => {
  return contactsOverlayDropdownRef?.value?.checkedEvents?.filter(
    (item: any) => item !== 0 && item !== -1 && !isObject(item) && !isString(item)
  )?.length
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const onCheckBatchTransactions = () => {
  emit('onCheckBatchTransactions')
}

const handleCheckAllChange = (val: any) => {
  checkedCurrencies.value = val ? currenciesData.value?.map((item: any) => item.symbol) : []
  checkCurrenciesAll.value = val
  isIndeterminate.value = false
  reportStore.transactionFilter.currencySymbols = checkedCurrencies.value
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const handleCheckedCitiesChange = (value: any[]) => {
  const checkedCount = value.length
  checkAll.value = checkedCount === currenciesData.value?.length
  isIndeterminate.value = checkedCount > 0 && checkedCount < currenciesData.value?.length
  reportStore.transactionFilter.currencySymbols = checkedCurrencies.value
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onCheckSourceAllChange = (val: any) => {
  const result = [
    sourcesData.value?.wallet.map((wallet: any) => wallet.sourceId),
    sourcesData.value?.exchange.map((exchange: any) => exchange.sourceId),
    sourcesData.value?.csv.map((csv: any) => csv.sourceId),
    sourcesData.value?.api.map((api: any) => api.sourceId)
  ]
  checkedSources.value = val ? result : [[], [], [], []]
  sourceState.checkAll = val
  sourceState.walletCheckAll = val
  sourceState.exchangeCheckAll = val
  sourceState.csvCheckAll = val
  sourceState.apiCheckAll = val
  sourceState.isIndeterminate = false
  sourceState.walletIsIndeterminate = false
  sourceState.exchangeIsIndeterminate = false
  sourceState.csvIsIndeterminate = false
  sourceState.apiIsIndeterminate = false
  reportStore.transactionFilter.sourceIds = [
    ...checkedSources.value[0],
    ...checkedSources.value[1],
    ...checkedSources.value[2],
    ...checkedSources.value[3]
  ]
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onCheckSourceClassifyAllChange = (type: any, index: number, val: any) => {
  const result: any = sourcesData.value[type].map((item: any) => item.sourceId)
  checkedSources.value[index] = val ? result : []
  sourceState[`${type}IsIndeterminate`] = false
  const checkedAllCount = checkedSources.value.reduce((acc, curr) => acc + curr.length, 0)
  const allDataCount = Object.values(sourcesData.value)
    .filter(Array.isArray)
    .reduce((acc, curr) => acc + curr.length, 0)
  reportStore.transactionFilter.sourceIds = [
    ...checkedSources.value[0],
    ...checkedSources.value[1],
    ...checkedSources.value[2],
    ...checkedSources.value[3]
  ]
  sourceState.checkAll = checkedAllCount === allDataCount
  sourceState.isIndeterminate = checkedAllCount > 0 && checkedAllCount < allDataCount
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onCheckedSourcesChange = (type: string, value: any[]) => {
  const checkedCount = value.length
  const checkedAllCount = checkedSources.value.reduce((acc, curr) => acc + curr.length, 0)
  const allDataCount = Object.values(sourcesData.value)
    .filter(Array.isArray)
    .reduce((acc, curr) => acc + curr.length, 0)
  sourceState.checkAll = checkedAllCount === allDataCount
  sourceState.isIndeterminate = checkedAllCount > 0 && checkedAllCount < allDataCount
  sourceState[`${type}CheckAll`] = checkedCount === sourcesData.value[type].length
  sourceState[`${type}IsIndeterminate`] = checkedCount > 0 && checkedCount < sourcesData.value[type].length
  reportStore.transactionFilter.sourceIds = [
    ...checkedSources.value[0],
    ...checkedSources.value[1],
    ...checkedSources.value[2],
    ...checkedSources.value[3]
  ]
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onChangeType = () => {
  if (typeValue.value === 'ALL') {
    reportStore.transactionFilter = omit(reportStore.transactionFilter, 'direction')
  } else {
    reportStore.transactionFilter.direction = typeValue.value === 'IN' ? 'IN' : 'OUT'
  }
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onChangeTime = () => {
  if (dateTimeValue.value[0] === 'Invalid Date' || dateTimeValue.value[1] === 'Invalid Date') {
    reportStore.transactionFilter = omit(reportStore.transactionFilter, 'startDate')
    reportStore.transactionFilter = omit(reportStore.transactionFilter, 'endDate')
  } else {
    // eslint-disable-next-line prefer-destructuring
    reportStore.transactionFilter.startDate = dateTimeValue.value[0]
    // eslint-disable-next-line prefer-destructuring
    reportStore.transactionFilter.endDate = dateTimeValue.value[1]
  }
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

const onOpenDateTime = () => {
  if (isDisabled.value) return
  unref(popoverRef)?.handleOpen?.()
}

const onCloseDateTime = () => {
  unref(popoverRef)?.handleClose?.()
}

const checkAllSource = () => {
  const result: any = [
    sourcesData.value?.wallet.map((wallet: any) => wallet.sourceId),
    sourcesData.value?.exchange.map((exchange: any) => exchange.sourceId),
    sourcesData.value?.csv.map((csv: any) => csv.sourceId),
    sourcesData.value?.api.map((api: any) => api.sourceId)
  ]
  checkedSources.value = result
  const checkedAllCount = checkedSources.value.reduce((acc, curr) => acc + curr.length, 0)
  const allDataCount = Object.values(sourcesData.value)
    .filter(Array.isArray)
    .reduce((acc, curr) => acc + curr.length, 0)
  sourceState.checkAll = checkedAllCount === allDataCount
  sourceState.isIndeterminate = checkedAllCount > 0 && checkedAllCount < allDataCount
  sourceState.walletCheckAll = !!result[0].length
  sourceState.exchangeCheckAll = !!result[1].length
  sourceState.csvCheckAll = !!result[2].length
  sourceState.apiCheckAll = !!result[3].length
  sourceState.walletIsIndeterminate = !result[0].length
  sourceState.exchangeIsIndeterminate = !result[1].length
  sourceState.csvIsIndeterminate = !result[2].length
  sourceState.apiIsIndeterminate = !result[3].length
}

const resetFilter = () => {
  if (isDisabled.value) return
  if (route.query?.noSet === '1') {
    window.history.replaceState({}, '', `${window.location.pathname}`)
    window.location.reload()
  }
  reportStore.transactionFilter = {}
  reportStore.transactionFilter.categoryNos = eventOverlayDropdownRef.value?.checkedEvents
  typeValue.value = 'ALL'
  checkAllSource()
  checkedCurrencies.value = currenciesData.value?.length ? currenciesData.value?.map((item: any) => item.symbol) : []
  checkCurrenciesAll.value = currenciesData.value?.length === checkedCurrencies.value?.length
  dateTimeValue.value = ''
  isIndeterminate.value = false
  eventOverlayDropdownRef.value?.onResetSelectedAll()
  contactsOverlayDropdownRef.value?.onResetSelectedAll()
  reportStore.transactionFilter.categoryNos = eventOverlayDropdownRef.value?.checkedEvents
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

watch(
  () => currenciesData.value,
  () => {
    if (currenciesData.value.length) {
      checkedCurrencies.value = currenciesData.value?.length
        ? currenciesData.value?.map((item: any) => item.symbol)
        : []
      checkCurrenciesAll.value = currenciesData.value?.length === checkedCurrencies.value?.length
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => sourcesData.value,
  () => {
    checkAllSource()
  },
  { deep: true, immediate: true }
)
watch(
  () => route.query,
  () => {
    if (route.name === 'reports-transactions-for-review' || route.name === 'reports-transactions-categorized') {
      nextTick(() => {
        if (!reportStore.transactionFilter.categoryNos?.length)
          reportStore.transactionFilter.categoryNos = eventOverlayDropdownRef.value?.checkedEvents
      })
    }
  },
  { immediate: true }
)
// onMounted(() => {})
</script>

<style lang="scss" scoped>
.elv-transactions-screening-container {
  padding-top: 8px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .elv-transactions-screening-content {
    display: flex;
    align-items: center;
  }

  .elv-transactions-screening-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    height: 30px;
    border: 1px solid #edf0f3;
    border-radius: 16px;
    cursor: pointer;
    margin-right: 8px;

    &.is-disabled {
      cursor: not-allowed;
    }

    &:not(.is-disabled):hover {
      border: 1px solid #5e85eb;

      .elv-transactions-screening-button__icon {
        fill: #5e85eb;
      }
    }
  }

  .elv-transactions-screening-button__icon {
    fill: #96a4ca;
  }

  .elv-transactions-screening-button__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    height: 14px;
    padding-right: 4px;
    color: #1e2024;
    margin-left: 4px;
  }

  .elv-transactions-screening-button__total {
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1e2024;
    padding-left: 8px;
    border-left: 1px solid #edf0f3;
  }

  .elv-transactions-screening-reset {
    display: flex;
    align-items: flex-start;
    padding: 0px 8px;
    width: 49px;
    height: 15px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;
    border: none;

    &.is-disabled {
      cursor: not-allowed;
    }

    &:not(.is-disabled):hover {
      color: #5e85eb;
      background-color: transparent;
    }

    &:active,
    &:focus {
      background-color: transparent;
    }
  }
}
</style>
<style lang="scss">
.elv-transactions-screening-type-select {
  .el-input__inner,
  .el-input__suffix {
    display: none;
  }

  .el-input.is-focus .el-input__wrapper,
  .el-input__wrapper {
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px;
  }

  .el-input.is-disabled .el-input__wrapper {
    box-shadow: none !important;
    background-color: transparent;
  }

  .el-input__prefix-inner > :last-child {
    margin-right: 0px;
  }

  .el-tree-select__popper .el-tree-node__expand-icon,
  .el-select__tags {
    display: none;
  }
}

.elv-transactions-screening-type-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 4px 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    width: 124px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #394048;

    &.hover {
      background: #f9fafb;
    }
  }
}

.el-popover.elv-transactions-screening-currencies-popper {
  .el-scrollbar__view,
  .el-scrollbar {
    width: 100%;
  }
}

.el-popover.elv-transactions-screening-currencies-popper,
.el-popover.elv-transactions-screening-sources-popper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 140px;
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .el-checkbox-group {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .el-checkbox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    width: 100%;
    height: 32px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    margin-right: 0px;

    &.is-checked {
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1e2024;
      }
    }

    &:not(.elv-transactions-screening-currencies-checkAll):not(.elv-transactions-screening-sources-checkAll):not(.elv-transactions-screening-sources-group-checkAll):hover {
      background: #f9fafb;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #1753eb;

      &:after {
        left: 3.5px;
      }
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #fff;

      &::before {
        height: 2.5px;
        top: 4.5px;
        background-color: #1753eb;
      }
    }

    .el-checkbox__inner {
      width: 13.5px;
      height: 13.5px;
      box-sizing: border-box;
      border: 1.5px solid #d0d4d9;
      background: #fff;
    }

    .el-checkbox__input {
      width: 18px;
      height: 18px;
      display: flex;
      justify-items: center;
      align-items: center;
    }

    .el-checkbox__label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        display: block;
      }

      span {
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

  .elv-transactions-screening-currencies-checkAll,
  .elv-transactions-screening-sources-checkAll {
    background: #f9fafb;
    border-bottom: 1px solid #edf0f3;

    span {
      font-weight: 700 !important;
    }
  }
}

.el-popover.elv-transactions-screening-sources-popper {
  .el-scrollbar {
    width: 100%;
  }

  .elv-transactions-screening-sources-group-checkAll {
    border-top: 1px solid #edf0f3;

    span {
      font-weight: 700 !important;
    }
  }

  .el-checkbox.elv-transactions-screening-sources-group-checkAll:nth-of-type(2) {
    border-top: 0px;
  }
}

.elv-transactions-screening-dateTime-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-range-picker {
    &.has-sidebar {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }

    // .el-picker-panel__icon-btn.d-arrow-left,
    // .el-picker-panel__icon-btn.d-arrow-right {
    //   display: none;
    // }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}
</style>
